import './_projects.scss';

const projects = function() {

};

document.addEventListener('DOMContentLoaded', function(){
    projects()
}, false);

window.addEventListener('acf/loaded/projects', function (e) {
    projects()
});